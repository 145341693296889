.nav {
  ul {
    display: flex;
    padding: 1rem;
    gap: 0.5rem;
    margin: 0;
  }

  li {
    list-style: none;
  }
}

.content {
  padding: 1rem;
  max-width: 40rem;
  margin: 0 auto;
}

.logout {
  margin-left: auto;

  /* appear like a normal anchor element */
  button {
    background: none;
    border: none;
    color: blue;
    text-decoration: underline;
    font-size: inherit;

    &:hover {
      cursor: pointer;
    }
  }
}

.app {
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

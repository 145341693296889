.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  border: 6px solid #323232;
  border-color: #323232 transparent #323232 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
